import React from 'react'
import AppBackDrop from '@/components/organisms/AppBackDrop/AppBackDrop'

type BackDropSuspenceProps = {
  children: React.ReactNode
}

const FallBack = <AppBackDrop forceOpen />

export default function BackDropSuspence({ children }: BackDropSuspenceProps) {
  if (typeof window === 'undefined') {
    return FallBack
  }

  return <React.Suspense fallback={FallBack}>{children}</React.Suspense>
}
