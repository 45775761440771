const URL_SYNC_NAMESPACE = 'RecoilURLSync'

const JSON = {
  STORE_KEY: `${URL_SYNC_NAMESPACE}JSONKey`,
  PARAM_KEY: 'state',
} as const

/**
 * recoil-syncのURL処理で利用する定数
 * 各storeKeyはユニークである必要がある
 * 本番環境で既に利用されているキーを後から変更してはならない
 * →URL上のパラメータ名も変わってしまい互換性が崩れてしまう
 */
export const RECOIL_URL_SYNC_KEYS = {
  NAMESPACE: URL_SYNC_NAMESPACE,
  JSON,

  // ダイアログ
  DIALOG_V3: {
    STORE_KEY: `${JSON.STORE_KEY}/Dialog`,
    PARAM_KEY: 'dialogV3',
  },
} as const
